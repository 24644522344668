import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

body{
    background:#261f3b;
    color: #E1E1E6;
    -webkit-font-smoothing: antialiased;
}

body, input, button{
    font-family: 'Questrial', sans-serif;
    font-size: 15px;
}

h1, h2, h3, h4, h5, h6, strong{
font-weight: 500;
}

button{
    cursor: pointer;
}
`