import React from 'react';

import { Container, Conteudo, Texto, Icone } from './styles';

function Opcao({ texto = '', icone, url = '', corFundo = '', instagram = false }) {
    return (
        <Container target="_blank" href={url}>
            <Conteudo corFundo={corFundo} instagram={instagram}>
                <Icone src={icone}/>
                <Texto>{texto}</Texto>
            </Conteudo>
        </Container>
    );
}

export default Opcao;