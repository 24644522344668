import React from 'react';

import { Container } from './styles';

import avatarImage from '../../assets/images/avatar.png';

function Avatar() {
    return (
        <Container>
            <img src={avatarImage} alt="Perfil de Paulo Henrique Torres"/>
        </Container>
    )
}

export default Avatar;