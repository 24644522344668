import React from 'react';
import { Container, Rodape } from './styles';
import Avatar from '../../components/Avatar';
import Texto from '../../components/Texto';
import Opcao from '../../components/Opcao';

import githubIcon from '../../assets/icons/github.svg';
import instagramIcon from '../../assets/icons/instagram.svg';
import linkedinIcon from '../../assets/icons/linkedin.svg';
import rocketseatIcon from '../../assets/icons/rocketseat.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import Titulo from '../../components/Titulo';

const Home = () => {
    return (
        <Container>
            <Avatar />
            <Titulo>Quer tirar aquela sua ideia de App ou Web Site do papel? Eu posso te ajudar!</Titulo>
            <Texto>Me chamo Paulo Henrique Torres mas pode me chamar de PH.</Texto>
            <Texto>Já desenvolvi soluções de todos os portes para empresas do ramo financeiro, saúde, conteúdo entre outras.</Texto>
            <Texto>Me segue e vamos conversar:</Texto>
            <Opcao
                texto="Github"
                icone={githubIcon}
                url="https://github.com/phtorres"
                corFundo="#24292e" />
            <Opcao
                texto="Twitter"
                icone={twitterIcon}
                url="https://twitter.com/PhTorres32"
                corFundo="#1DA1F2" />
            <Opcao
                texto="Instagram"
                icone={instagramIcon}
                url="https://www.instagram.com/phtorres32/"
                corFundo="#1DA1F2"
                instagram />
            <Opcao
                texto="LinkedIn"
                icone={linkedinIcon}
                url="https://www.linkedin.com/in/paulo-henrique-torres-a4474a196/"
                corFundo="#0A66C2" />
            <Opcao
                texto="Rocketseat"
                icone={rocketseatIcon}
                url="https://app.rocketseat.com.br/me/phtorres"
                corFundo="#8257E6" />

                <Rodape>
                    <Texto>Web and Mobile developer</Texto>
                    <Texto url="https://www.bennu.com.br/">@Bennu</Texto>
                </Rodape>
        </Container>
    );
}

export default Home;