import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "../pages/home";
import Info from "../pages/info";

export default function Rotas() {
    return (
        <Router>
            <Switch>
                <Route exact path="/info">
                    <Info />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}