import React from 'react';
import Rotas from './routes';
import GlobalStyles from './styles/global';

const App = () => {
  return (
    <>
      <Rotas />
      <GlobalStyles />
    </>
  )
}

export default App;
