import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding: 30px;
  justify-content:center;
  align-items:center;
`;

export const Rodape = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  margin-top:15px;
  gap: 3px;
`;
