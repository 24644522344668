import styled, { css } from 'styled-components';

export const Container = styled.a`
  text-decoration:none;
  width: 300px;
  margin-top: 15px;
`;

export const Conteudo = styled.div`
  background: ${props => props.corFundo};
  padding: 10px;
  border-radius: 10px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;

  ${props => props.instagram && css`
  background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000);
  `};
`;

export const Texto = styled.p`
    color: #E1E1E6;
    margin-left: 10px;
    font-size: 18px;
    font-weight:bold;
`;

export const Icone = styled.img`

width: 30px;
height: 30px;

`;
