import React from 'react';

import { Container, Fonte, Link } from './styles';

function Texto({ url = '', children }) {
  return (
    <Container>
      {url?<Link target="_blank" href={url}>{children}</Link>:<Fonte>{children}</Fonte>}
    </Container>
  );
}

export default Texto;