import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 5px 0;
`;

export const Fonte = styled.p`

text-align:center;

${props => props.destaque && css`
color:#988BC7;
`};

`;

export const Link = styled.a`

text-align:center;
color:#988BC7;
text-decoration:none;

`;

