import styled from 'styled-components';

export const Container = styled.div`
  

border: solid 4px #9146ff;
width:200px;
height:200px;
border-radius:50%;

display:flex;
justify-content:center;
align-items:center;
margin-bottom:15px;
  img{
    width:193px;
    height:193px;
    border-radius:50%;
  }
`;
